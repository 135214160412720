import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Main from "../../layouts/Main";
import Container from "../../components/Container";
import { Contact, Hero } from "./components";

const ContactPage = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Main>
      <Contact />
    </Main>
  );
};

export default ContactPage;
