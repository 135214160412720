import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

import Container from "../../../../components/Container";
import { Link } from "@mui/material";

const Contact = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const mock = [
    {
      label: t("Email"),
      value: <Link href="mailto:info@bogunov.co.jp">info@bogunov.co.jp</Link>,
      icon: (
        <svg
          width={20}
          height={20}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
        </svg>
      ),
    },
    {
      label: t("Address"),
      value: <Trans>1-36-2-3F, Shinjuku, Shinjuku-ku, Tokyo, 160-0022</Trans>,
      icon: (
        <svg
          width={20}
          height={20}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      label: (
        <Link href="https://www.linkedin.com/in/airinhoshino" target="_blank">
          LinkedIn
        </Link>
      ),
      icon: <LinkedInIcon width={20} height={20} />,
    },
    {
      label: (
        <Link href="https://www.facebook.com/Bogunovsoft" target="_blank">
          Facebook
        </Link>
      ),
      icon: <FacebookIcon width={20} height={20} />,
    },
    {
      label: (
        <Link href="https://www.youtube.com/user/elviss24rus" target="_blank">
          YouTube
        </Link>
      ),
      icon: <YouTubeIcon width={20} height={20} />,
    },
  ];

  const LeftSide = (): JSX.Element => {
    return (
      <Box>
        <Box marginBottom={2}>
          <Typography variant={"h4"} sx={{ fontWeight: 700 }} gutterBottom>
            <Trans>Contacts</Trans>
          </Typography>
          <Typography
            color="text.secondary"
            pr="5rem"
            sx={{ wordBreak: "keep-all" }}
          >
            <Trans>Ready to work together? Don’t hesitate to contact us.</Trans>
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          {mock.map((item, i) => (
            <Box
              key={i}
              component={ListItem}
              disableGutters
              width={"auto"}
              padding={0}
              sx={{
                mt: item.value ? 0 : "0.45rem",
                mb: item.value ? 0 : "0.45rem",
              }}
            >
              <Box
                component={ListItemAvatar}
                minWidth={"auto !important"}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  width={40}
                  height={40}
                  sx={{ backgroundColor: "#00509e" }}
                >
                  {item.icon}
                </Box>
              </Box>
              <ListItemText
                sx={{ wordBreak: "keep-all" }}
                primary={item.label}
                secondary={item.value}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const RightSide = (): JSX.Element => {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.4549451901967!2d139.70815591525906!3d35.69042068019218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cc2b88bffff%3A0x949670026a204aa5!2z44ix44OG44Kj44OD44OX44K544OI44OD44Kv!5e0!3m2!1sen!2sjp!4v1651032862291!5m2!1sen!2sjp"
        width="100%"
        height="100%"
        style={{
          border: "0",
          minHeight: 300,
          filter:
            theme.palette.mode === "dark"
              ? "grayscale(0.5) opacity(0.7)"
              : "none",
        }}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          position={"relative"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            width={1}
            // order={{ xs: 2, md: 1 }}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: "0 0 100%", md: "0 0 50%" },
              position: "relative",
              maxWidth: { xs: "100%", md: "50%" },
              minHeight: { xs: 300, md: 600 },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: "50vw" },
                height: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    left: "0%",
                    width: 1,
                    height: 1,
                    position: { xs: "relative", md: "absolute" },
                  }}
                >
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};

export default Contact;
